import { render, staticRenderFns } from "./mortgageDetails.vue?vue&type=template&id=759c0ced&scoped=true&"
import script from "./mortgageDetails.vue?vue&type=script&lang=js&"
export * from "./mortgageDetails.vue?vue&type=script&lang=js&"
import style0 from "./mortgageDetails.vue?vue&type=style&index=0&id=759c0ced&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "759c0ced",
  null
  
)

export default component.exports